import React from 'react'
// import uniqid from 'uniqid'
import { experience } from '../../portfolio'
import './Experience.css'

const ExperienceCard = ({ exp }) => {
  console.log(exp.description)
  return (
    <div className='experience__card'>
      <div className='title'>
        <div>
          <h4> {exp.title}</h4>
        </div>
        <div>
          <h5>{exp.timeline}</h5>
        </div>
      </div>
      <h5>{exp.company}</h5>
      <h5>{exp.location}</h5>
      {exp.description.map((line) => (
        <p>{line}</p>
      ))}
    </div>
  )
}

const Experience = () => {
  const experiences = experience
  if (!experiences.length) return null
  return (
    <section id='experience' className='section'>
      <h2 className='section__title'>Experience</h2>
      {experiences.map((exp) => (
        <ExperienceCard exp={exp} />
      ))}
    </section>
  )
}

export default Experience
