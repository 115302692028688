import './Footer.css'

const year = new Date().getFullYear();

const Footer = () => (
  <footer className='footer'>
    <a
      href='https://writwords.net'
      className='link footer__link'
    >
      @{year} Aryan Patel.
    </a>
  </footer>
)

export default Footer
