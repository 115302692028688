const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://kilroyhere.github.io',
  title: 'AP.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Aryan Patel',
  // role: 'an experimenter and learner',
  description:"I'm a recent UCLA grad in Computer Science. I'm a curious listener with a knack for tackling tough problems, and like everyone else, I'm chasing the next big thing!",
  // resume: 'https://aryanpatel.net/resume', // TODO
  social: {
    linkedin: 'https://linkedin.com/in/aryan-p',
    github: 'https://github.com/kilroyhere',
  },
}


const projects = [
  // projects can be added and removed
  // if there are no projects, Projects section won't show up
  {
    name: 'NGINX Web Server',
    description:
      'Architected a Google Cloud hosted WebServer in C++ using Boost’s ASIO and Beast, adhering to HTTP 1.1 & NGINX standards. Implemented a state machine architecture with request handling factories for robust request parsing, scalability, and performance. Designed and optimized asynchronous client sessions, leveraging multi-threading for multiple user sessions to efficiently manage multiple simultaneous connections.',
    stack: ['C++', 'Boost.Beast', 'Gtest', 'Gcovr'],
  },
  {
    name: 'TCP over UDP',
    description:
      'Architected a server-client set of programs that communicate using UDP packets over BSD Sockets. Ensured data reliability with data sequencing, cumulative acknowledgements, and AIMD congestion control. Successfully transferred over 100 MiB files from 10+ parallel clients to a single server instance.',
    stack: ['C++', 'POSIX Sockets'],
  },
  {
    name: 'MiniRogue',
    description:
      'Developed the classic game Rogue using ASCII graphics with OOP concepts in C++. Utilized inheritance, polymorphism, and templating with a well-designed class structure for a modular code base. Uses recursive backtracking to find the closest step towards the player to move the NPC villains.',
    stack: ['C++', 'OOP', 'Algorithms', 'Data Structures'],
    livePreview: 'https://replit.com/@kilroyHere/Project3#readme.md',
  },
]



const experience = [
  {
    title: 'Software Engineer',
    company: 'Northrop Grumman Corporation (Mission Systems)',
    location: 'Woodland Hills, CA',
    timeline: 'Aug 2023 - Present',
    description: [
      '• Integrated the latest generation of GPS receivers by upgrading legacy software used in 250+ Tornado Aircraft.',
      '• Implemented and tested proprietary networking protocol in C and Ada to handle communication between the Navigation system and Aircraft flight and targeting systems.',
      '• Tuned the navigation estimation model (Kalman Filter) to calculate high precision positional data using the upgraded GPS receiver with added sources.'
    ],
  },
  {
    title: 'Software Engineer Intern',
    company: 'Northrop Grumman Corporation (Mission Systems)',
    location: 'Woodland Hills, CA',
    timeline: 'Jun 2022 - Sep 2022',
    description: [
      '• Developed a sensor calibration software deployed across 80+ Hypersonic vehicles and projectiles, ensuring the functionality of Inertial Navigation Systems in environments requiring absolute radio silence.',
      '• Optimized an Interrupt Service Routine written in C for bare metal FPGA, enhancing calibration.',
      '• Optimized accelerometer data calibration by dividing load in interrupts, reducing processing time and achieving an 11% speedup.',
      '• Updated a custom data transfer protocol over Ethernet for transmission of customer telemetry metrics.'
    ],
  },
  {
    title: 'Engineering Manager and Backend Lead',
    company: 'PeerUpX @ Creative Labs UCLA',
    location: 'Los Angeles, CA',
    timeline: 'Oct 2021 - Jan 2022',
    description: [
      '• Developed a web-based Mental Health platform spanning 3 universities and 30+ counselors.',
      '• Implemented REST APIs using Node.JS that ran CRUD operations for counselors profiles stored in MongoDB.',
      '• Used webSockets to allow real-time synchronization of features including chatting and scheduling.',
      '• Secured endpoints using bcrypt to ensure secure communication between counselors and students.'
    ],
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'C',
  'C++',
  'Python',
  'JavaScript',
  'Bash',
  'Haskell',
  'ADA',
  'SQL',
  'Node.JS',
  'Flask',
  'C++ Boost',
  'GTest',
  'Cmake',
  'Git',
  'LaTeX',
  'Google Cloud Platform',
  'MongoDB',
  'OpenAPI',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'aryan0211@g.ucla.edu',
}

export { header, about, projects, skills, experience, contact }
